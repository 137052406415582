<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Date</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <!-- <vs-input type="date" v-model="date" class="w-full" /> -->
      <date-range-picker
        style="min-height: 40px"
        class="w-full"
        ref="picker"
        opens="center"
        :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
        :singleDatePicker="false"
        :timePicker="false"
        :timePicker24Hour="false"
        :showWeekNumbers="false"
        :showDropdowns="false"
        :autoApply="true"
        :clear="true"
        v-model="validity"
        :linkedCalendars="true"
      >
        <template v-slot:input="picker">
          {{ dateFormat(picker.startDate) }} -
          {{ dateFormat(picker.endDate) }}
        </template>
      </date-range-picker>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      validity: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
  },
  watch: {
    validity() {
      // merge start and end date and format it to YYYY-MM-DD
      let startDate = moment(this.validity.startDate).format("YYYY-MM-DD");
      let endDate = moment(this.validity.endDate).format("YYYY-MM-DD");
      let formatDate = startDate + "," + endDate;
      this.$emit("data", formatDate);
    },
  },
};
</script>
