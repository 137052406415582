<template>
  <vx-card :title="title">
    <!-- <Map :data="map" ref="map" /> -->

    <div class="vx-row mb-6 mt-4">
      <div class="vx-col w-1/2">
        <select-operating-unit
          :isMultiple="false"
          @data="setOperatingUnit"
        ></select-operating-unit>
        <select-territory
          @data="setTerritory"
          ref="select_territory"
          :operatingUnit="operatingUnits"
          :isMultiple="false"
        ></select-territory>
        <!-- <select-sales-channel
          @data="setSalesChannel"
          ref="select_sales_channel"
        >
        </select-sales-channel> -->
        <select-supervisor
          @data="setSupervisor"
          ref="select_supervisor"
        ></select-supervisor>
        <select-salesman-subtype
          @data="setSalesmanSubtype"
          ref="select_salesman_subtype"
        >
        </select-salesman-subtype>
        <select-salesman
          @data="setSalesman"
          ref="select_salesman"
          :personal_id="personal_id"
          :territory_id="territory_id"
          :sales_channel_id="sales_channel_id"
        >
        </select-salesman>
        <!-- <input-date @data="setDate" ref="input_date"></input-date> -->

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full flex items-center">
            <span>Date</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input v-model="date" type="date" class="w-full mb-6" />
          </div>
        </div>
      </div>
    </div>

    <!-- button -->
    <div class="vx-row mb-6">
      <div class="vx-col flex gap-2">
        <vs-button
          @click="onClickBtnSearch"
          class="w-full"
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-search"
        >
          Search
        </vs-button>
        <vs-button
          @click="onClickBtnReset"
          class="w-full"
          color="danger"
          type="filled"
          icon-pack="feather"
          icon="icon-refresh-cw"
        >
          Reset
        </vs-button>
      </div>
    </div>

    <!-- table -->
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <h4>Summary Customer Visit</h4>
      </div>
      <div class="core vx-col w-full mb-base">
        <datatable ref="datatable"></datatable>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import SelectOperatingUnit from "./_components/select-operating-unit.vue";
import SelectTerritory from "./_components/select-territory.vue";
import SelectSalesChannel from "./_components/select-sales-channel.vue";
import SelectSupervisor from "./_components/select-supervisor.vue";
import SelectSalesman from "./_components/select-salesman.vue";
import SelectSalesmanSubtype from "./_components/select-salesman-subtype.vue";
import InputDate from "./_components/input-date.vue";
import Map from "./_components/map.vue";
import Datatable from "./_components/datatable.vue";

export default {
  components: {
    Datatable,
    SelectOperatingUnit,
    SelectTerritory,
    SelectSalesChannel,
    SelectSupervisor,
    SelectSalesman,
    InputDate,
    SelectSalesmanSubtype,
    Map,
  },
  data() {
    return {
      title: "Monitoring Dashboard SFA",
      datatable: [],
      date: null,
      salesman: {
        id: null,
        name: null,
      },
      salesman_subtypes: [],
      operatingUnits: [],
      territorries: [],
      salesChannel: {
        id: null,
        code: null,
      },
      supervisors: [],
      map: [],
      isExportExcel: false,
      personal_id: null,
      territory_id: null,
      sales_channel_id: null,
      bounds: null,
    };
  },
  mounted() {},
  methods: {
    async onClickBtnSearch() {
      if (this.date == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select date",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      let salesman_ids = [];
      salesman_ids = this.$refs.select_salesman.selected;
      if (salesman_ids.length > 0) {
        salesman_ids = await salesman_ids.map((item) => item.personal_id);
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select salesman",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      const territorryIDs = await this.territorries.map((item) => item.id);

      // fetch from datatable ref
      const date_format = this.date + "," + this.date;
      await this.$refs.datatable.setDate(date_format);
      await this.$refs.datatable.setPersonalIDs(salesman_ids);
      await this.$refs.datatable.setTerritoryIDs(territorryIDs);
      await this.$refs.datatable.getData();
    },
    fetchSearch(params) {
      this.$vs.loading();
      const baseUrl =
        "/api/sfa/v1/monitoring-dashboard/customer-visit-dashboard";
      this.$http.get(baseUrl, {}).then((resp) => {
        if (resp.code == 200) {
          if (resp.data == null) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: "Data not found",
              color: "danger",
            });
            return;
          }
          this.datatable = resp.data;
          this.map = resp.data.map((item) => {
            if (
              item.visit_line_latitude != "" &&
              item.visit_line_longitude != ""
            ) {
              let obj = {
                label: item.salesman_name,
                latlng: [
                  parseFloat(item.visit_line_latitude),
                  parseFloat(item.visit_line_longitude),
                ],
              };

              return obj;
            }
          });

          this.$vs.loading.close();
          // trigger fit bounds method
          setTimeout(() => {
            this.$refs.map.fitBounds(this.map);
          }, 1000);
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
          });
        }
      });
    },
    fetchExport(params) {
      this.$vs.loading();
      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `MONITORING_DASHBOARD_REPORT_${currentDate}`;
      this.$http
        .get(`api/sfa/v1/monitoring-dashboard/customer-visit-dashboard`, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
          params: params,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute("download", fileTitle + ".xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    setDate(date) {
      this.date = date;
    },
    setSalesman(id, name) {
      this.salesman.id = id;
      this.salesman.name = name;
    },
    setSalesmanSubtype(selecteds) {
      this.salesman_subtypes = selecteds.map((item) => {
        return {
          personal_id: item.personal_id,
          work_position_id: item.work_position_id,
          name: item.name,
        };
      });
      this.personal_id = null;
      this.territory_id = null;
    },
    setSupervisor(selecteds) {
      this.supervisors = selecteds.map((item) => {
        return item.personal_id;
      });
    },
    setSalesChannel(id, code) {
      this.salesChannel.id = id;
      this.salesChannel.code = code;
    },
    setOperatingUnit(selecteds) {
      this.operatingUnits = selecteds.map((item) => {
        return {
          id: item.id,
          code: item.code,
        };
      });
    },
    setTerritory(selecteds) {
      this.territorries = selecteds.map((item) => {
        return {
          id: item.territory_id,
          code: item.code,
        };
      });
      this.territory_id = null;
      this.personal_id = null;
    },
    onClickBtnReset() {
      // reload
      this.$router.go(0);
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },
  watch: {
    operatingUnits: function (val) {
      if (val.length == 0) {
        return;
      }
      let ids = val.map((item) => item.id);
      let params = {
        operating_unit_ids: ids,
        length: 0,
      };
      this.$refs.select_territory.getData(params);
    },
    territorries: function (val) {
      if (val.length == 0) {
        return;
      }
      this.$refs.select_salesman.reset();
      this.$refs.select_salesman.setType("territory");
      let params = {
        territory_ids: val.map((item) => item.id),
        length: 0,
      };
      this.$refs.select_salesman.getDataByTerritory(params);

      params = {
        territory_ids: val.map((item) => item.id),
        length: 0,
      };
      this.$refs.select_supervisor.getData(params);
    },
    supervisors: function (val) {
      if (val.length == 0) {
        return;
      }
      this.$refs.select_salesman.reset();
      this.$refs.select_salesman.setType("supervisor");
      let params = {
        sales_spv_ids: val,
        length: 0,
      };
      this.$refs.select_salesman.getDataByTerritoryV2(params);

      this.$refs.select_salesman_subtype.reset();
      params = {
        sales_spv_ids: val,
        length: 0,
      };
      this.$refs.select_salesman_subtype.getData(params);
    },
    salesman_subtypes: function (val) {
      if (val.length == 0) {
        return;
      }

      this.$refs.select_salesman.reset();
      this.$refs.select_salesman.setType("territory");
      let params = {
        sales_spv_ids: this.supervisors.map((item) => item),
        subtype_ids: val.map((item) => item.work_position_id),
        length: 0,
      };
      this.$refs.select_salesman.getDataByTerritoryV2(params);
    },
  },
};
</script>
