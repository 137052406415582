<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Supervisor</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <multiselect
        v-model="selected"
        :options="options"
        placeholder="Type to search"
        track-by="persona_work_id_number"
        :custom-label="customLabel"
        multiple
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      baseUrl: "/api/sfa/v1/monitoring-dashboard/supervisor",
      options: [],
      selected: [],
    };
  },
  methods: {
    customLabel(option) {
      return `${option.persona_work_id_number} - ${option.personal_name}`;
    },
    getData(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              var options = [];
              resp.data.records.forEach(function (item) {
                options.push(item);
              }, options);
              this.options = options;
            } else {
              this.options = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
  },
  watch: {
    selected(v) {
      this.$emit("data", v);
    },
  },
  mounted() {},
};
</script>
