<template>
  <l-map
    style="height: 400px"
    :zoom="zoom"
    :center="center"
    ref="mapObject"
    :bounds="bounds"
  >
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-marker :lat-lng="item.latlng" :key="index" v-for="(item, index) in data">
      <l-tooltip :options="{ permanent: true, interactive: true }">
        <div>
          <b>{{ item.label }}</b>
          <br />
        </div>
      </l-tooltip>
    </l-marker>
  </l-map>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import { Icon, L } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      // center: [-7.963226, 112.628806],
      center: [-6.17511, 106.865036],
      bounds: null,
    };
  },
  methods: {
    fitBounds() {
      var L = window.L;
      const latlngs = this.data.map((item) => item.latlng);
      const bounds = L.latLngBounds(latlngs);
      this.$refs.mapObject.fitBounds(bounds);
    },
    setView(data) {
      if (data == null) return;
      const latlngs = data.map((item) => item.latlng);
      const bounds = L.latLngBounds(latlngs);
      this.bounds = bounds;
    },
  },
};
</script>
