<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :data="table.data"
      :max-items="table.length"
      :sst="false"
      @change-page="handleChangePage"
      :total="table.total"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>

      <template slot="thead">
        <vs-th>Action</vs-th>
        <vs-th>No</vs-th>
        <vs-th>Sales Office</vs-th>
        <vs-th>Salesman Name</vs-th>
        <vs-th>Call Plan</vs-th>
        <vs-th>Visited (In Route)</vs-th>
        <vs-th>Visited (%)</vs-th>
        <vs-th>Visited (Out Of Route)</vs-th>
        <vs-th>Effective Call (In Route)</vs-th>
        <vs-th>Effective Call (Out Of Route)</vs-th>
        <vs-th>Actual Sales</vs-th>
        <vs-th>Actual Collection</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <vs-button
              @click="onClickBtnDetail(tr)"
              size="small"
              color="success"
              icon-pack="feather"
              icon="icon-eye"
              title="Detail"
              style="margin-right: 5px"
            />
          </vs-td>
          <vs-td>{{ table.row_number_start + indextr }}</vs-td>
          <vs-td>{{ tr.territory_name }}</vs-td>
          <vs-td>{{ tr.personal_name }}</vs-td>
          <vs-td>{{ tr.call_plan }}</vs-td>
          <vs-td>{{ tr.visited_in_route }}</vs-td>
          <vs-td>{{ tr.visited_percentage }}%</vs-td>
          <vs-td>{{ tr.visited_out_of_route }}</vs-td>
          <vs-td>{{ tr.effective_call_in_route }}</vs-td>
          <vs-td>{{ tr.effective_call_out_of_route }}</vs-td>
          <vs-td>{{ formatNumber(tr.actual_sales) }}</vs-td>
          <vs-td>{{ formatNumber(tr.actual_collection) }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import { formatNumber } from "@/utils/common";
export default {
  data() {
    return {
      baseUrl: "/api/sfa/v1/monitoring-dashboard/customer-visit-dashboard",
      table: this.tableDefaultState(),
      date: "",
      personalIDs: [],
    };
  },
  methods: {
    formatNumber,
    async setDate(date) {
      this.date = date;
    },
    async setPersonalIDs(personalIDs) {
      this.personalIDs = personalIDs;
    },
    async setTerritoryIDs(territoryIDs) {
      this.territoryIDs = territoryIDs;
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        row_number_start: 1,
        row_number_end: 0,
      };
    },
    onClickBtnDetail(data) {
      // data.date is "2021-01-01,2021-01-31"
      let startDate = this.date.split(",")[0];
      let endDate = this.date.split(",")[1];
      this.$router.push({
        name: "monitoring-dashboard-sfa-show",
        query: {
          start_date: startDate,
          end_date: endDate,
          personal_id: data.personal_id,
        },
      });
    },
    async getData() {
      if (this.date == "") {
        return;
      }
      this.$vs.loading();
      try {
        const response = await this.$http.get(this.baseUrl, {
          params: {
            date: this.date,
            salesman_id: this.personalIDs,
            territory_ids: this.territoryIDs,
            page: this.table.page,
            length: this.table.length,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        });
        if (response.code == 200) {
          this.table.total = response.data.total_record;
          this.table.totalPage = response.data.total_page;
          this.table.totalSearch = response.data.total_record_search;
          this.table.length = response.data.total_record_per_page;
          this.table.data = response.data.records;
          this.table.row_number_start = response.data.row_number_start;
          this.table.row_number_end = response.data.row_number_end;

          this.$vs.notify({
            title: "Success",
            text: response.message,
            color: "success",
            position: "top-right",
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: response.message,
            color: "danger",
            position: "top-right",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
          position: "top-right",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  computed: {
    setPage: {
      get() {
        return this.table.page;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
