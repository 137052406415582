<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Operating Unit</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <multiselect
        v-model="selected"
        :options="options"
        placeholder="Type to search"
        track-by="name"
        label="name"
        :multiple="isMultiple"
        :allow-empty="true"
      >
        <span slot="noResult">Oops! No data found</span>
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title"
              >{{ props.option.code }} {{ props.option.name }}</span
            >
          </span>
        </template>

        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title"
              >{{ props.option.code }} {{ props.option.name }}</span
            >
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isMultiple: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/operating-unit",
      options: [],
      selected: null,
    };
  },
  methods: {
    reset() {
      this.selected = null;
      this.options = [];
    },
    getData(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              var options = [];
              resp.data.records.forEach(function (item) {
                options.push(item);
              }, options);
              this.options = options;
            } else {
              this.options = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
  },
  watch: {
    selected() {
      // check selected is object, return single array
      var results = [];
      if (this.selected != null && typeof this.selected === "object") {
        results = [this.selected];
      } else if (this.selected == null) {
        results = [];
      } else {
        results = this.selected;
      }

      this.$emit("data", results);
    },
  },
  mounted() {
    this.getData({
      length: 0,
    });
  },
};
</script>
