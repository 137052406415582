<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Salesman Subtype</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <multiselect
        v-model="selected"
        :options="options"
        placeholder="Type to search"
        track-by="work_position_id"
        label="subtype_name"
        multiple
        :allow-empty="true"
        @search-change="getData({ search: $event })"
      >
        <span slot="noResult">Oops! No data found</span>
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ props.option.subtype_name }}</span>
          </span>
        </template>

        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ props.option.subtype_name }}</span>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      baseUrl: "/api/sfa/v1/monitoring-dashboard/salesman-subtype",
      options: [],
      selected: null,
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {
    getData(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              var options = [];
              resp.data.records.forEach(function (item) {
                options.push(item);
              }, options);
              this.options = options;
            } else {
              this.options = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    reset() {
      this.selected = null;
      this.options = [];
    },
  },
  watch: {
    selected(v) {
      this.$emit("data", v);
    },
  },
};
</script>
