<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span
        >Salesman
        <small class="text-xs" style="color: red"
          >* (Don't choose if you want to see all)</small
        ></span
      >
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <multiselect
        v-model="selected"
        :options="options"
        placeholder="Type to search"
        track-by="personal_id"
        :allow-empty="true"
        :multiple="true"
        :custom-label="customLabel"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    territory_id: {
      type: Number,
      default: null,
    },
    sales_channel_id: {
      type: Number,
      default: null,
    },
    personal_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      baseUrl1: "/api/sfa/v1/personal-territory",
      baseUrl2: "/api/sfa/v1/sales-channel/personal-sales-channel",
      baseUrl3: "/api/sfa/v1/monitoring-dashboard/supervisor-salesman",
      baseUrl4: "/api/sfa/v1/personal-territory/salesman-monitoring-dasbhboard",
      options: [],
      selected: null,
      type: null,
    };
  },
  methods: {
    onSearch(query) {
      let params = {
        company_id: this.$store.state.user.currentUser.company_id,
        search: query,
      };
      if (this.type == "territory") {
        this.getDataByTerritory(query);
      } else if (this.type == "sales-channel") {
        this.getDataBySalesChannel(query);
      }
    },
    reset() {
      this.selected = null;
      this.options = [];
      this.type = null;
    },
    setType(type) {
      this.type = type;
    },
    customLabel(option) {
      if (this.type == "territory") {
        return option.personal.name;
      } else if (this.type == "sales-channel") {
        return option.personal_name;
      } else if (this.type == "supervisor") {
        return `${option.personal.code_external} - ${option.personal.name}`;
      }
    },
    getDataByTerritory(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl1, {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              var options = [];
              resp.data.records.forEach(function (item) {
                options.push(item);
              }, options);
              this.options = options;
            } else {
              this.options = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getDataByTerritoryV2(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl4, {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              var options = [];
              resp.data.records.forEach(function (item) {
                options.push(item);
              }, options);
              this.options = options;
            } else {
              this.options = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getDataBySalesChannel(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl2, {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            var options = [];
            resp.data.records.forEach(function (item) {
              options.push(item);
            }, options);
            this.options = options;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      if (this.type == "territory") {
        this.$emit("data", v.personal_id, v.personal.name);
      } else if (this.type == "sales-channel") {
        this.$emit("data", v.personal_id, v.personal_name);
      }
    },
  },
  mounted() {},
};
</script>
